import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 10000,
});

// Get token from localStorage
const getToken = () => localStorage.getItem('token');

// Refresh token function
const refreshToken = async () => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/refresh-token`,
      {
        refresh_token: localStorage.getItem('refreshToken'),
      }
    );
    if (response.data.token) {
      localStorage.setItem('token', response.data.token);
      if (response.data.refresh_token) {
        localStorage.setItem('refreshToken', response.data.refresh_token);
      }
      return response.data.token;
    }
    throw new Error('No token received');
  } catch (error) {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    window.location.href = '/login';
    throw error;
  }
};

// Request interceptor
api.interceptors.request.use(
  async (config) => {
    // Create new config object instead of modifying the parameter
    const newConfig = { ...config };
    const token = getToken();

    if (token) {
      newConfig.headers = {
        ...newConfig.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return newConfig;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
api.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    const originalRequest = error.config;

    // Handle 401 (Unauthorized)
    if (error.response?.status === 401 && !originalRequest.hasRetried) {
      originalRequest.hasRetried = true;

      try {
        // Try to refresh the token
        const newToken = await refreshToken();
        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        // If refresh token fails, redirect to login
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }

    // Handle other errors
    const errorMessage = error.response?.data || error.message;

    // You can add custom error handling here
    switch (error.response?.status) {
      case 400:
        console.error('Bad Request:', errorMessage);
        break;
      case 403:
        console.error('Forbidden:', errorMessage);
        break;
      case 404:
        console.error('Not Found:', errorMessage);
        break;
      case 500:
        console.error('Server Error:', errorMessage);
        break;
      default:
        console.error('Error:', errorMessage);
    }

    return Promise.reject(errorMessage);
  }
);

export default api;
