import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';
import logoImg from 'src/assets/images/ahedge_blue_logo.png';

// Register Roboto font
Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
      fontWeight: 500,
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    color: '#333',
    padding: 40,
    position: 'relative',
  },
  container: {
    flex: 1,
    padding: 15,
    position: 'relative',
    zIndex: 1,
  },
  watermark: {
    position: 'absolute',
    top: '30%',
    left: '25%',
    right: '25%',
    transform: 'translate(-50%, -50%) rotate(-45deg)',
    opacity: 0.05,
    width: '55%',
    height: '55%',
    zIndex: 0,
  },
  watermarkImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  // topLine: {
  //   position: 'absolute',
  //   top: 0,
  //   left: 0,
  //   right: 0,
  //   height: 5,
  //   backgroundColor: '#145EA9',
  //   zIndex: 2,
  // },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderBottom: '1 solid #eee',
    paddingBottom: 10,
    marginBottom: 15,
    position: 'relative',
    zIndex: 1,
  },
  candidateInfo: {
    flex: 1,
  },
  name: {
    fontSize: 22,
    fontWeight: 700,
    color: '#333',
    marginBottom: 6,
  },
  contactRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  contactLabel: {
    fontSize: 11,
    fontWeight: 500,
    color: '#555',
  },
  contactText: {
    fontSize: 11,
    color: '#555',
  },
  logo: {
    width: 120,
    height: 'auto',
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 14,
    color: '#145EA9',
    borderBottom: '2 solid #145EA9',
    paddingBottom: 5,
    marginBottom: 12,
    fontWeight: 500,
  },
  personalRow: {
    flexDirection: 'row',
    marginBottom: 6,
  },
  personalLabel: {
    width: 80,
    fontSize: 11,
    fontWeight: 500,
    color: '#555',
  },
  personalValue: {
    flex: 1,
    fontSize: 11,
    color: '#555',
  },
  table: {
    width: '100%',
    marginVertical: 8,
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#f9fafb',
    padding: '10 5',
    borderBottom: '1 solid #e5e7eb',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottom: '1 solid #e5e7eb',
    padding: '10 5',
    minHeight: 35,
  },
  institutionCell: {
    flex: 3,
    paddingRight: 8,
  },
  qualificationCell: {
    flex: 1.5,
  },
  yearCell: {
    flex: 1,
    textAlign: 'center',
  },
  scoreCell: {
    flex: 1,
    textAlign: 'right',
  },
  headerText: {
    fontSize: 11,
    color: '#6b7280',
    fontWeight: 500,
  },
  cellText: {
    fontSize: 11,
    color: '#374151',
  },
  chipContainer: {
    marginTop: 5,
  },
  chip: {
    marginBottom: 4,
  },
  companyCell: {
    flex: 3,
    paddingRight: 8,
  },
  durationCell: {
    flex: 1,
    textAlign: 'right',
  },
  companyName: {
    fontSize: 11,
    color: '#374151',
    fontWeight: 500,
    marginBottom: 2,
  },
  roleText: {
    fontSize: 10,
    color: '#6b7280',
  },
});

// Reusable Watermark component
const PageWithWatermark = ({ children }) => (
  <Page size="A4" style={styles.page}>
    <View style={styles.watermark}>
      <Image src={logoImg} style={styles.watermarkImage} />
    </View>
    <View style={styles.container}>{children}</View>
  </Page>
);

const ResumePDF = ({ data }) => {
  const formatMonth = (monthNum) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return months[parseInt(monthNum, 10) - 1];
  };

  const hasWorkExperience = data.workExperiences?.some(
    (exp) => exp?.company && exp?.role && exp?.employment_period?.startMonth
  );

  const hasSkills = data.skills?.length > 0 || data.otherSkills;
  const hasCertifications =
    data.certifications?.length > 0 || data.otherCertifications;

  return (
    <Document>
      <PageWithWatermark>
        {/* Header Section */}
        <View style={styles.header}>
          <View style={styles.candidateInfo}>
            <Text style={styles.name}>
              {`${data.firstname} ${
                data.middlename ? `${data.middlename} ` : ''
              }${data.lastname}`}
            </Text>
            {/* <View style={[styles.contactRow, { marginTop: 8 }]}>
              <Text style={styles.contactLabel}>Email: </Text>
              <Text style={styles.contactText}>{data.email}</Text>
            </View>
            <View style={styles.contactRow}>
              <Text style={styles.contactLabel}>Contact: </Text>
              <Text style={styles.contactText}>{data.contact}</Text>
            </View> */}
          </View>
          <Image src={logoImg} style={styles.logo} />
        </View>

        {/* Personal Information */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Personal Information</Text>
          <View style={styles.personalRow}>
            <Text style={styles.personalLabel}>DOB:</Text>
            <Text style={styles.personalValue}>{data.dob}</Text>
          </View>
          {/* <View style={styles.personalRow}>
            <Text style={styles.personalLabel}>Address:</Text>
            <Text style={styles.personalValue}>
              {data.addressLine1}
              {data.city && data.state ? `, ${data.city}, ${data.state}` : ''}
              {data.zipCode ? ` - ${data.zipCode}` : ''}
            </Text>
          </View> */}
        </View>

        {/* Education Section */}
        <View style={styles.section} wrap={false}>
          <Text style={styles.sectionTitle}>Educational Details</Text>
          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <Text style={[styles.headerText, styles.institutionCell]}>
                Institution
              </Text>
              <Text style={[styles.headerText, styles.qualificationCell]}>
                Qualification
              </Text>
              <Text style={[styles.headerText, styles.yearCell]}>Year</Text>
              <Text style={[styles.headerText, styles.scoreCell]}>
                Score (%)
              </Text>
            </View>
            {data.educations?.map((edu) => (
              <View
                key={`${edu.qualification}-${edu.institution}`}
                style={styles.tableRow}
              >
                <Text style={[styles.cellText, styles.institutionCell]}>
                  {edu.institution}
                </Text>
                <Text style={[styles.cellText, styles.qualificationCell]}>
                  {edu.qualification}
                </Text>
                <Text style={[styles.cellText, styles.yearCell]}>
                  {edu.graduationYear}
                </Text>
                <Text style={[styles.cellText, styles.scoreCell]}>
                  {edu.calculatedScore}
                </Text>
              </View>
            ))}
          </View>
        </View>

        {/* Work Experience Section */}
        {hasWorkExperience && (
          <View style={styles.section} wrap={false}>
            <Text style={styles.sectionTitle}>Work Experience</Text>
            <View style={styles.table}>
              <View style={styles.tableHeader}>
                <Text style={[styles.headerText, styles.companyCell]}>
                  Company
                </Text>
                <Text style={[styles.headerText, styles.durationCell]}>
                  Duration
                </Text>
              </View>
              {data.workExperiences
                ?.filter(
                  (exp) =>
                    exp?.company &&
                    exp?.role &&
                    exp?.employment_period?.startMonth
                )
                .map((exp) => (
                  <View
                    key={`${exp.company}-${exp.role}`}
                    style={styles.tableRow}
                  >
                    <View style={styles.companyCell}>
                      <Text style={styles.companyName}>{exp.company}</Text>
                      <Text style={styles.roleText}>Role: {exp.role}</Text>
                    </View>
                    <Text style={[styles.cellText, styles.durationCell]}>
                      {formatMonth(exp.employment_period.startMonth)}{' '}
                      {exp.employment_period.startYear} -{' '}
                      {formatMonth(exp.employment_period.endMonth)}{' '}
                      {exp.employment_period.endYear}
                    </Text>
                  </View>
                ))}
            </View>
          </View>
        )}

        {/* Skills Section */}
        {hasSkills && (
          <View style={styles.section} wrap={false}>
            <Text style={styles.sectionTitle}>Skills</Text>
            <View style={styles.chipContainer}>
              {data.skills?.map((skill) => (
                <View key={`skill-${skill}`} style={styles.chip}>
                  <Text style={styles.cellText}>{skill}</Text>
                </View>
              ))}
              {data.otherSkills &&
                data.otherSkills.split(',').map((skill) => (
                  <View key={`other-skill-${skill.trim()}`} style={styles.chip}>
                    <Text style={styles.cellText}>{skill.trim()}</Text>
                  </View>
                ))}
            </View>
          </View>
        )}

        {/* Certifications Section */}
        {hasCertifications && (
          <View style={styles.section} wrap={false}>
            <Text style={styles.sectionTitle}>Certifications</Text>
            <View style={styles.chipContainer}>
              {data.certifications?.map((cert) => (
                <View key={`cert-${cert}`} style={styles.chip}>
                  <Text style={styles.cellText}>{cert}</Text>
                </View>
              ))}
              {data.otherCertifications &&
                data.otherCertifications.split(',').map((cert) => (
                  <View key={`other-cert-${cert.trim()}`} style={styles.chip}>
                    <Text style={styles.cellText}>{cert.trim()}</Text>
                  </View>
                ))}
            </View>
          </View>
        )}
      </PageWithWatermark>
    </Document>
  );
};

export default ResumePDF;
