import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { Input } from 'src/components/ui/input';
import { Button } from 'src/components/ui/button';
import { CalendarIcon } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';
import { useToast } from 'src/hooks/use-toast';
import { educations, boardOptions } from './constants';
import { AddButton } from './common';

// Base Form Field Components
// Update the FormField component in EducationFields
const FormField = ({ label, id, errorMessage, children, required = false }) => (
  <div className="space-y-2">
    <label htmlFor={id} className="block text-sm font-medium text-gray-700">
      {label}
      {required && <span className="text-red-500 ml-1">*</span>}
    </label>
    <div className="relative">
      {React.cloneElement(children, {
        id,
        'aria-labelledby': id,
        className: `${children.props.className || ''} ${
          errorMessage ? 'border-red-500 focus:border-red-500' : ''
        }`,
      })}
    </div>
    {errorMessage && (
      <p className="mt-1 text-xs text-red-500">{errorMessage}</p>
    )}
  </div>
);

// Reusable Form Components
const FormSelect = ({
  name,
  control,
  options,
  placeholder,
  icon,
  disabled,
  rules = { required: true },
  defaultValue,
  onChange,
  value,
  'data-field': dataField,
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field, fieldState: { error } }) => (
      <div
        data-field={dataField}
        style={{ scrollMarginTop: '100px' }}
        tabIndex={-1}
      >
        <Select
          onValueChange={(val) => {
            field.onChange(val);
            if (onChange) {
              onChange(val);
            }
          }}
          value={value || field.value}
          defaultValue={defaultValue || field.value}
          disabled={disabled}
        >
          <SelectTrigger
            className={`${error ? 'border-red-500 focus:border-red-500' : ''}`}
          >
            <div className="flex items-center">
              {icon}
              <SelectValue placeholder={placeholder} />
            </div>
          </SelectTrigger>
          <SelectContent>
            {Array.isArray(options) &&
              options.map((option) => (
                <SelectItem
                  key={option?.id || option?.value}
                  value={option?.value || option?.id?.toString()}
                >
                  {option?.label || option?.name}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      </div>
    )}
  />
);

const RadioButton = ({ id, value, checked, onChange, label }) => (
  <div className="flex items-center space-x-2 cursor-pointer">
    <input
      type="radio"
      id={id}
      value={value}
      checked={checked}
      onChange={onChange}
      className="w-4 h-4 text-primary border-gray-300 focus:ring-primary"
    />
    <label
      htmlFor={id}
      className="text-sm font-medium text-gray-700 cursor-pointer"
    >
      {label}
    </label>
  </div>
);

const validateNewQualification = (
  allEducations,
  newQualification,
  currentIndex
) => {
  const otherEducations = allEducations.filter(
    (_, index) => index !== currentIndex
  );

  if (newQualification === 'TENTH') {
    const hasTenth = otherEducations.some(
      (edu) => edu.qualification === 'TENTH'
    );
    if (hasTenth) {
      return {
        isValid: false,
        message: 'Secondary qualification can only be added once',
      };
    }
  }

  if (newQualification === 'TWELFTH') {
    const hasTwelfth = otherEducations.some(
      (edu) => edu.qualification === 'TWELFTH'
    );
    if (hasTwelfth) {
      return {
        isValid: false,
        message: 'Higher Secondary qualification can only be added once',
      };
    }
  }

  return { isValid: true };
};

const getFieldOfStudyOptions = (qualification, isEnabled, masterData) => {
  if (!isEnabled) {
    return [];
  }

  if (qualification === 'TWELFTH') {
    return masterData.fieldsofstudieshsc || [];
  }

  return masterData.fieldsofstudies || [];
};

// Utility functions
const generateGPAOptions = (maxGPA) => {
  if (!maxGPA) return [];
  const numMaxGPA = parseFloat(maxGPA);
  if (Number.isNaN(numMaxGPA)) return [];

  return Array.from({ length: Math.floor(numMaxGPA * 10) + 1 }, (_, i) => ({
    value: (i / 10).toFixed(1),
    label: (i / 10).toFixed(1),
  }));
};

const generateYearOptions = () => {
  const currentYear = new Date().getFullYear();
  return Array.from(
    { length: currentYear - 1980 + 1 },
    (_, i) => currentYear - i
  )
    .reverse()
    .map((year) => ({
      value: year.toString(),
      label: year.toString(),
    }));
};

const formatGPAValue = (value) => {
  if (!value) return undefined;
  return value.includes('.') ? value : `${value}.0`;
};

// Field Components
const GradeInputs = ({ type, index, control, watch, errors }) => {
  const maxMarksValue = watch(`educations.${index}.maxMarks`);
  const earnedMarksValue = watch(`educations.${index}.earnedMarks`);
  const maxGPAValue = watch(`educations.${index}.maxGPA`);
  console.log('maxGPAValue ', maxGPAValue, index);
  const earnedGPAValue = watch(`educations.${index}.earnedGPA`);
  // const scoredGPAValue = Number.isInteger(
  //   parseFloat(watch(`educations.${index}.earnedGPA`))
  // )
  //   ? `${watch(`educations.${index}.earnedGPA`)}.0`
  //   : watch(`educations.${index}.earnedGPA`);
  // console.log('scoredGPAValue ', scoredGPAValue, index);
  // Format the GPA values correctly
  const formattedMaxGPA = formatGPAValue(maxGPAValue);
  const formattedEarnedGPA = formatGPAValue(earnedGPAValue);

  if (type === 'marks') {
    return (
      <div className="grid grid-cols-2 gap-4">
        <FormField
          label="Max Marks"
          id={`max-marks-${index}`}
          data-field={`max-marks-${index}`}
          errorMessage={errors?.educations?.[index]?.maxMarks?.message}
          required
        >
          <Controller
            name={`educations.${index}.maxMarks`}
            data-field={`educations.${index}.maxMarks`}
            control={control}
            rules={{ required: true, min: 0 }}
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                className={
                  errors?.educations?.[index]?.maxMarks ||
                  (earnedMarksValue &&
                    maxMarksValue &&
                    parseFloat(earnedMarksValue) > parseFloat(maxMarksValue))
                    ? 'border-red-500 ring-red-500 focus:ring-red-500'
                    : ''
                }
              />
            )}
          />
        </FormField>
        <FormField
          label="Scored Marks"
          id={`earned-marks-${index}`}
          data-field={`earned-marks-${index}`}
          errorMessage={
            errors?.educations?.[index]?.earnedMarks?.message ||
            (earnedMarksValue &&
            maxMarksValue &&
            parseFloat(earnedMarksValue) > parseFloat(maxMarksValue)
              ? 'Scored marks cannot exceed maximum marks'
              : '')
          }
          required
        >
          <Controller
            name={`educations.${index}.earnedMarks`}
            data-field={`educations.${index}.earnedMarks`}
            control={control}
            rules={{
              required: true,
              min: 0,
              validate: (val) => {
                const maxMarks = watch(`educations.${index}.maxMarks`);
                return (
                  !maxMarks ||
                  parseFloat(val) <= parseFloat(maxMarks) ||
                  'Scored marks cannot exceed maximum marks'
                );
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                className={
                  errors?.educations?.[index]?.earnedMarks ||
                  (earnedMarksValue &&
                    maxMarksValue &&
                    parseFloat(earnedMarksValue) > parseFloat(maxMarksValue))
                    ? 'border-red-500 ring-red-500 focus:ring-red-500'
                    : ''
                }
              />
            )}
          />
        </FormField>
      </div>
    );
  }

  const gpaOptions = [
    { value: '5.0', label: '5.0' },
    { value: '10.0', label: '10.0' },
  ];

  return (
    <div className="grid grid-cols-2 gap-4">
      <FormField
        label="Max GPA"
        id={`max-gpa-${index}`}
        data-field={`max-gpa-${index}`}
        errorMessage={errors?.educations?.[index]?.maxGPA?.message}
        required
      >
        <FormSelect
          name={`educations.${index}.maxGPA`}
          data-field={`educations.${index}.maxGPA`}
          control={control}
          options={gpaOptions}
          placeholder="Max"
          // defaultValue={maxGPAValue ? `${maxGPAValue}.0` : undefined}
          // value={maxGPAValue ? `${maxGPAValue}.0` : undefined}
          value={formattedMaxGPA}
        />
      </FormField>
      <FormField
        label="Scored GPA"
        id={`earned-gpa-${index}`}
        data-field={`earned-gpa-${index}`}
        errorMessage={errors?.educations?.[index]?.earnedGPA?.message}
        required
      >
        <FormSelect
          name={`educations.${index}.earnedGPA`}
          data-field={`educations.${index}.earnedGPA`}
          control={control}
          options={generateGPAOptions(maxGPAValue?.replace('.0', ''))}
          placeholder="Scored"
          rules={{
            required: true,
            validate: (val) => {
              const maxGPA = watch(`educations.${index}.maxGPA`);
              return (
                !maxGPA ||
                parseFloat(val) <= parseFloat(maxGPA) ||
                'Scored GPA cannot exceed maximum GPA'
              );
            },
          }}
          // value={scoredGPAValue ? `${scoredGPAValue}` : undefined}
          // value={earnedGPAValue}
          value={formattedEarnedGPA}
        />
      </FormField>
    </div>
  );
};

const GradeTypeSelector = ({
  index,
  onGradeTypeChange,
  control,
  watch,
  errors,
}) => {
  const currentGradeType = watch(
    `educations.${index}.gradeType`
  )?.toLowerCase();

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-start gap-12">
        {['marks', 'gpa'].map((type) => (
          <RadioButton
            key={type}
            id={`${type}-${index}`}
            value={type}
            checked={currentGradeType === type}
            onChange={(e) => onGradeTypeChange(e.target.value)}
            label={
              type === 'gpa'
                ? 'GPA'
                : type.charAt(0).toUpperCase() + type.slice(1)
            }
          />
        ))}
      </div>
      <div className="max-w-xs">
        <GradeInputs
          type={currentGradeType}
          index={index}
          control={control}
          watch={watch}
          errors={errors}
        />
      </div>
    </div>
  );
};

// Main Component
export const EducationFields = ({
  index,
  control,
  watch,
  onRemove,
  errors,
  education,
  setValue,
  masterData,
}) => {
  const { toast } = useToast();
  const [gradeType, setGradeType] = useState(
    education?.gradeType?.toLowerCase() || 'marks'
  );
  // const [showAlert, setShowAlert] = useState(false);
  // const [alertMessage, setAlertMessage] = useState('');
  const allEducations = watch('educations');
  const qualification = watch(`educations.${index}.qualification`);

  useEffect(() => {
    setValue(`educations.${index}.gradeType`, gradeType.toUpperCase());
  }, []);

  const handleGradeTypeChange = (newType) => {
    setGradeType(newType);
    setValue(`educations.${index}.gradeType`, newType.toUpperCase());

    const clearFields =
      newType === 'marks'
        ? ['maxGPA', 'earnedGPA']
        : ['maxMarks', 'earnedMarks'];

    clearFields.forEach((field) => {
      setValue(`educations.${index}.${field}`, '');
    });
  };

  const isSchoolLevel =
    qualification === 'TENTH' || qualification === 'TWELFTH';
  const isFieldOfStudyEnabled = ['TWELFTH', 'DEGREE', 'MASTERS'].includes(
    qualification
  );

  const handleQualificationChange = (value) => {
    const validationResult = validateNewQualification(
      allEducations,
      value,
      index
    );

    if (!validationResult.isValid) {
      toast({
        title: 'Qualification Error',
        description: validationResult.message,
        variant: 'destructive',
      });
      return;
    }

    setValue(`educations.${index}.qualification`, value);
    // Reset board/university based on qualification
    if (value === 'TENTH' || value === 'TWELFTH') {
      setValue(`educations.${index}.university`, '');
    } else {
      setValue(`educations.${index}.board`, '');
    }
  };

  return (
    <div className="relative p-6 rounded-lg border border-gray-300 bg-gray-50 space-y-4">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-8">
          <FormField
            label="Institution"
            id={`institution-${index}`}
            errorMessage={errors?.educations?.[index]?.institution?.message}
            required
          >
            <Controller
              name={`educations.${index}.institution`}
              control={control}
              rules={{ required: true }}
              data-field={`educations.${index}.institution`}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Enter institution name"
                  className={
                    errors?.educations?.[index]?.institution
                      ? 'border-red-500 ring-red-500 focus:ring-red-500'
                      : ''
                  }
                />
              )}
            />
          </FormField>
        </div>
        <div className="col-span-12 md:col-span-4">
          <FormField
            label="Year of passing"
            id={`graduation-year-${index}`}
            errorMessage={errors?.educations?.[index]?.graduationYear?.message}
            required
          >
            <FormSelect
              name={`educations.${index}.graduationYear`}
              control={control}
              options={generateYearOptions()}
              placeholder="Select year"
              icon={<CalendarIcon className="mr-2 h-4 w-4" />}
              data-field={`educations.${index}.graduationYear`}
              rules={{ required: true }}
            />
          </FormField>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-4">
          <FormField
            label="Qualification"
            id={`qualification-${index}`}
            errorMessage={errors?.educations?.[index]?.qualification?.message}
            required
          >
            <FormSelect
              name={`educations.${index}.qualification`}
              control={control}
              options={educations}
              placeholder="Select Qualification"
              data-field={`educations.${index}.qualification`}
              rules={{
                required: 'Qualification is required',
                validate: (value) => {
                  const result = validateNewQualification(
                    allEducations,
                    value,
                    index
                  );
                  return result.isValid || result.message;
                },
              }}
              onChange={handleQualificationChange}
            />
          </FormField>
        </div>
        {qualification && (
          <>
            <div className="col-span-12 md:col-span-4">
              <FormField
                label="Field of Study"
                id={`field-of-study-${index}`}
                errorMessage={
                  errors?.educations?.[index]?.fieldsOfStudy?.message
                }
                required={isFieldOfStudyEnabled}
              >
                <FormSelect
                  name={`educations.${index}.fieldsOfStudy`}
                  control={control}
                  options={getFieldOfStudyOptions(
                    qualification,
                    isFieldOfStudyEnabled,
                    masterData
                  )}
                  placeholder="Select Field of Study"
                  disabled={!isFieldOfStudyEnabled}
                  data-field={`educations.${index}.fieldsOfStudy`}
                  rules={{ required: isFieldOfStudyEnabled }}
                />
              </FormField>
            </div>
            <div className="col-span-12 md:col-span-4">
              {isSchoolLevel ? (
                <FormField
                  label="Board"
                  id={`board-${index}`}
                  data-field={`board-${index}`}
                  errorMessage={errors?.educations?.[index]?.board?.message}
                  required={isSchoolLevel}
                >
                  <FormSelect
                    name={`educations.${index}.board`}
                    control={control}
                    options={boardOptions}
                    placeholder="Select Board"
                    data-field={`educations.${index}.board`}
                    required={isSchoolLevel}
                  />
                </FormField>
              ) : (
                <FormField
                  label="University"
                  id={`university-${index}`}
                  errorMessage={
                    errors?.educations?.[index]?.university?.message
                  }
                  data-field={`university-${index}`}
                  required
                >
                  <Controller
                    name={`educations.${index}.university`}
                    control={control}
                    data-field={`educations.${index}.university`}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Enter university name"
                        className={
                          errors?.educations?.[index]?.university
                            ? 'border-red-500 ring-red-500 focus:ring-red-500'
                            : ''
                        }
                      />
                    )}
                  />
                </FormField>
              )}
            </div>
          </>
        )}
      </div>

      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-4">
          <GradeTypeSelector
            index={index}
            onGradeTypeChange={handleGradeTypeChange}
            control={control}
            watch={watch}
            errors={errors}
          />
        </div>
        {onRemove && (
          <div className="col-span-12 md:col-span-8">
            <div className="flex justify-end">
              <Button
                type="button"
                onClick={onRemove}
                size="sm"
                variant="outline"
                className="text-red-500 border-red-500 hover:bg-red-50"
              >
                <span className="text-sm">Remove Education</span>
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const AddEducationButton = ({ onClick }) => (
  <AddButton onClick={onClick} text="Add Education" />
);
