import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthActions } from 'src/hooks/useAuthActions';
import { useToast } from 'src/hooks/use-toast';

const GoogleCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { handleGoogleCallback } = useAuthActions();
  const { toast } = useToast();
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    const processCallback = async () => {
      try {
        const urlParams = new URLSearchParams(location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        if (!code) {
          throw new Error('No authorization code received');
        }

        await handleGoogleCallback(code, state);

        // Success handling is done in handleGoogleCallback
        setIsProcessing(false);
      } catch (error) {
        console.error('Google callback error:', error);
        toast({
          title: 'Error',
          description: error.message || 'Failed to complete Google login',
          variant: 'destructive',
        });
        navigate('/login', { replace: true });
      }
    };

    processCallback();
  }, [location, navigate, toast]);

  if (isProcessing) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin h-8 w-8 border-4 border-primary border-t-transparent rounded-full mx-auto mb-4" />
          <p className="text-gray-600">Completing login...</p>
        </div>
      </div>
    );
  }

  return null;
};

export default GoogleCallback;
