export const scrollToError = (errors) => {
  if (!errors || typeof errors !== 'object') return;

  const findFirstError = (obj, parentPath = '') => {
    if (Array.isArray(obj)) {
      return obj.reduce((foundError, item, index) => {
        if (foundError) return foundError;

        if (item && typeof item === 'object') {
          const fullPath = parentPath ? `${parentPath}.${index}` : `${index}`;
          const nestedError = findFirstError(item, fullPath);
          return nestedError || foundError;
        }

        return foundError;
      }, null);
    }

    if (obj && typeof obj === 'object') {
      return Object.entries(obj).reduce((foundError, [key, value]) => {
        if (foundError) return foundError;

        const fullPath = parentPath ? `${parentPath}.${key}` : key;

        if (value && value.type === 'required' && value.ref) {
          return value.ref.name;
        }

        if (typeof value === 'object' && value !== null) {
          const nestedError = findFirstError(value, fullPath);
          return nestedError || foundError;
        }

        return foundError;
      }, null);
    }

    return null;
  };

  const firstError = findFirstError(errors);

  if (!firstError) return;

  setTimeout(() => {
    const selector = `[data-field="${firstError}"]`;
    const element = document.querySelector(selector);

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      element.focus();

      const selectTrigger =
        element.querySelector('[role="combobox"]') ||
        element.closest('[role="combobox"]');

      if (selectTrigger) {
        selectTrigger.focus();
      }
    }
  }, 100);
};
