import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Eye, EyeOff } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { Button } from 'src/components/ui/button';
import { Input } from 'src/components/ui/input';
import { Alert, AlertDescription } from 'src/components/ui/alert';
import { UserService } from 'src/services/api/UserService';
import { useToast } from 'src/hooks/use-toast';
import { OTPVerification } from 'src/components/OTPVerification';
import { useGenericOTP } from 'src/hooks/useGenericOTP';
import pageBgImg from '../../assets/images/page_bg.svg';

const FormField = ({ label, children, error, id }) => (
  <div className="mb-4">
    <label
      htmlFor={id}
      className="block text-sm font-medium text-gray-700 mb-1"
    >
      {label}
    </label>
    {React.cloneElement(children, { id })}
    {error && <p className="mt-1 text-xs text-red-500">{error}</p>}
  </div>
);

const passwordValidation = {
  required: 'Password is required',
  minLength: {
    value: 8,
    message: 'Password must be at least 8 characters',
  },
  pattern: {
    value:
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    message:
      'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character',
  },
};

export function CreateAccount() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Separated data retrieval function
  const getUserData = () => {
    try {
      const userDataString = localStorage.getItem('userData');
      return userDataString ? JSON.parse(userDataString) : null;
    } catch (error) {
      console.error('Error getting user data:', error);
      return null;
    }
  };

  const userData = getUserData();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: userData?.email || '',
      password: '',
      confirmPassword: '',
    },
  });

  const emailValue = watch('email');
  const watchedPassword = watch('password');

  // Initialize email OTP hook
  const emailOtpHook = useGenericOTP(emailValue, 'email', toast);

  useEffect(() => {
    if (!userData) {
      navigate('/join-us');
      return () => {}; // Return empty cleanup function
    }

    const handleClickOutside = (event) => {
      const isPasswordField = event.target.name === 'password';
      if (!isPasswordField) {
        setShowPasswordTooltip(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [userData, navigate]);

  const onSubmit = async (data) => {
    try {
      if (!emailOtpHook.verified) {
        setApiError('Please verify your email first');
        return;
      }

      setIsLoading(true);
      setApiError('');

      const registrationData = {
        email: data.email,
        password: data.password,
        firstName: userData.firstName,
        lastName: userData.lastName,
        cid: userData.cid.toString(),
      };

      const response = await UserService.registerUser(registrationData);

      if (response.success) {
        localStorage.removeItem('userData');
        navigate('/login');
      }
    } catch (error) {
      console.error('Account creation error:', error);

      if (error.response?.status === 409) {
        setError('email', {
          type: 'manual',
          message: 'This email is already registered',
        });
      } else if (error.response?.status === 400) {
        const serverErrors = error.response.data.errors;
        if (serverErrors) {
          Object.keys(serverErrors).forEach((key) => {
            setError(key, {
              type: 'manual',
              message: serverErrors[key],
            });
          });
        }
      } else {
        setApiError(
          error.response?.data?.message ||
            'An error occurred during registration. Please try again.'
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (!userData) {
    return null;
  }

  const handleGoogleLogin = async () => {
    try {
      setIsLoading(true);
      setApiError('');
      const cid = userData.cid.toString();

      const response = await UserService.googleAuthSignup(cid);

      if (response.success && response.data.auth_url) {
        // Redirect to Google login
        window.location.href = response.data.auth_url;
      } else {
        throw new Error('Failed to get Google authentication URL');
      }
    } catch (error) {
      console.error('Google login error:', error);
      setApiError('Failed to initiate Google login. Please try again.');
      toast({
        title: 'Error',
        description: 'Failed to initiate Google login. Please try again.',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="grid place-items-center h-screen bg-gray-100"
      style={{
        backgroundImage: `url(${pageBgImg})`,
      }}
    >
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md mx-auto text-left">
        <div className="text-center mb-6">
          <h2 className="text-2xl font-bold ahBlueText mb-2">
            Congratulations!
          </h2>
          <h3 className="text-lg font-semibold">Continue to Your Account</h3>
        </div>

        {apiError && (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>{apiError}</AlertDescription>
          </Alert>
        )}

        {/* Google Button First */}
        <Button
          type="button"
          variant="outline"
          disabled={isLoading}
          className="w-full h-12"
          onClick={handleGoogleLogin}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            aria-hidden="true"
            className="mr-2 h-5 w-5"
          >
            <path
              fill="#EA4335"
              d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
            />
            <path
              fill="#4285F4"
              d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
            />
            <path
              fill="#FBBC05"
              d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
            />
            <path
              fill="#34A853"
              d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
            />
          </svg>
          Continue with Google
        </Button>

        {/* Divider */}
        <div className="relative my-6">
          <div className="absolute inset-0 flex items-center">
            <span className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-xs uppercase">
            <span className="bg-white px-2 text-gray-500">
              Or continue with email
            </span>
          </div>
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
        >
          <div style={{ display: 'none' }}>
            <input type="text" name="fakeusernameremembered" />
            <input type="password" name="fakepasswordremembered" />
          </div>

          <OTPVerification
            type="email"
            register={register}
            errors={errors}
            value={emailValue}
            verified={emailOtpHook.verified}
            isOTPSent={emailOtpHook.isOTPSent}
            isOTPLoading={emailOtpHook.isOTPLoading}
            showOTPInput={emailOtpHook.showOTPInput}
            isVerifyingOTP={emailOtpHook.isVerifyingOTP}
            otpError={emailOtpHook.otpError}
            canResend={emailOtpHook.canResend}
            resendTimeout={emailOtpHook.resendTimeout}
            handleSendOTP={emailOtpHook.handleSendOTP}
            handleVerifyOTP={emailOtpHook.handleVerifyOTP}
            readOnly
          />
          {emailOtpHook.verified && (
            <>
              <FormField
                label="Password"
                error={errors.password?.message}
                id="password"
              >
                <TooltipProvider>
                  <Tooltip open={showPasswordTooltip}>
                    <TooltipTrigger asChild>
                      <div className="relative">
                        <Input
                          type={showPassword ? 'text' : 'password'}
                          onFocus={() => setShowPasswordTooltip(true)}
                          {...register('password', passwordValidation)}
                        />
                        <button
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                          className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                        >
                          {showPassword ? (
                            <EyeOff className="h-4 w-4" />
                          ) : (
                            <Eye className="h-4 w-4" />
                          )}
                        </button>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent side="right" className="p-2">
                      <div className="text-sm">
                        Password must contain:
                        <div className="space-y-1 mt-1">
                          <div>• At least 8 characters</div>
                          <div>• One uppercase letter</div>
                          <div>• One lowercase letter</div>
                          <div>• One number</div>
                          <div>• One special character (@$!%*?&)</div>
                        </div>
                      </div>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </FormField>

              <FormField
                label="Confirm Password"
                error={errors.confirmPassword?.message}
                id="confirmPassword"
              >
                <div className="relative">
                  <Input
                    type={showConfirmPassword ? 'text' : 'password'}
                    {...register('confirmPassword', {
                      required: 'Please confirm your password',
                      validate: (value) =>
                        value === watchedPassword || 'Passwords do not match',
                    })}
                  />
                  <button
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                  >
                    {showConfirmPassword ? (
                      <EyeOff className="h-4 w-4" />
                    ) : (
                      <Eye className="h-4 w-4" />
                    )}
                  </button>
                </div>
              </FormField>
            </>
          )}

          <Button
            type="submit"
            className="w-full mt-6"
            disabled={isLoading || !emailOtpHook.verified}
          >
            {isLoading ? 'Creating Account...' : 'Create Account'}
          </Button>
        </form>
      </div>
    </div>
  );
}

export default CreateAccount;
