import React, { useState, useRef, useEffect } from 'react';

export const OTPInput = ({ length = 4, onChange, disabled, otpError }) => {
  const [otp, setOtp] = useState(Array(length).fill(''));
  const [hasError, setHasError] = useState(false);
  const inputRefs = useRef(
    Array(length)
      .fill(null)
      .map(() => React.createRef())
  );

  // Reset error state when OTP changes
  useEffect(() => {
    if (otp.some((digit) => digit !== '')) {
      setHasError(false);
    }
  }, [otp]);

  const focusInput = (index) => {
    if (index >= 0 && index < length) {
      inputRefs.current[index]?.current?.focus();
    }
  };

  const clearInputs = () => {
    if (hasError) {
      const newOtp = Array(length).fill('');
      setOtp(newOtp);
      onChange(newOtp.join(''));
      focusInput(0);
    }
  };

  const handleChange = (e, index) => {
    const value = e.target.value.replace(/\D/g, '').slice(-1);
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    onChange(newOtp.join(''));

    if (value && index < length - 1) {
      focusInput(index + 1);
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
      const newOtp = [...otp];

      if (newOtp[index]) {
        newOtp[index] = '';
        setOtp(newOtp);
        onChange(newOtp.join(''));
      } else if (index > 0) {
        focusInput(index - 1);
      }
    } else if (e.key === 'ArrowLeft' && index > 0) {
      e.preventDefault();
      focusInput(index - 1);
    } else if (e.key === 'ArrowRight' && index < length - 1) {
      e.preventDefault();
      focusInput(index + 1);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text/plain').slice(0, length);
    const pastedDigits = pastedData.replace(/\D/g, '').split('');

    const newOtp = [...otp];
    pastedDigits.forEach((digit, index) => {
      if (index < length) {
        newOtp[index] = digit;
      }
    });

    setOtp(newOtp);
    onChange(newOtp.join(''));

    const nextEmptyIndex = newOtp.findIndex((val) => !val);
    focusInput(nextEmptyIndex === -1 ? length - 1 : nextEmptyIndex);
  };

  // If there's an error and user clicks, clear all inputs
  const handleClick = (e) => {
    e.target.select();
    clearInputs();
  };

  // Set error state from parent through useEffect
  useEffect(() => {
    if (otpError) {
      setHasError(true);
    }
  }, [otpError]);

  return (
    <div className="flex gap-2 mt-2">
      {otp.map((digit, index) => {
        const inputId = `otp-input-${index}`;
        return (
          <input
            key={inputId}
            id={inputId}
            ref={inputRefs.current[index]}
            type="text"
            inputMode="numeric"
            pattern="\d*"
            maxLength={1}
            value={digit}
            disabled={disabled}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onPaste={handlePaste}
            onClick={handleClick}
            className={`w-12 h-12 text-center text-xl border rounded-md 
              focus:outline-none focus:ring-2 focus:ring-blue-500
              ${disabled ? 'bg-gray-100 cursor-not-allowed' : 'bg-white'}
              ${hasError ? 'border-red-500' : ''}
              transition-all duration-200`}
            autoComplete="off"
            aria-label={`OTP digit ${index + 1}`}
          />
        );
      })}
    </div>
  );
};
